<template>
  <b-container>
    <b-row>
      <b-table-simple small caption-top responsive bordered>
        <b-thead>
          <b-tr>
            <b-th>{{ !byUser ? 'User' : 'Module' }}</b-th>
            <b-th></b-th>
            <b-th></b-th>
            <b-th></b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in items" :key="index">
            <b-td>
              <div class="container">
                <p>{{ item.user_name }}</p>
              </div>
            </b-td>
            <b-td>
              <div
                class="
                  bg-default
                  p-s
                  d-flex
                  justify-content-between
                  align-items-center
                  r-c
                  border-bottom
                "
              >
                <span>T. HOURS:</span>
                <span>{{ Number(item.hours) }}</span>
              </div>
              <div
                class="
                  bg-default
                  p-s
                  d-flex
                  justify-content-between
                  align-items-center
                  r-c
                "
              >
                <span>OVERTIME:</span>
                <span>{{ item.overtime }}</span>
              </div>
            </b-td>
            <b-td>
              <div
                class="
                  bg-default
                  p-s
                  d-flex
                  justify-content-between
                  align-items-center
                  r-c
                  border-bottom
                "
              >
                <span>LATES:</span>
                <span>{{ item.lates }}</span>
              </div>
              <div
                class="
                  bg-default
                  p-s
                  d-flex
                  justify-content-between
                  align-items-center
                  r-c
                "
              >
                <span>JUSTIFIEDS:</span>
                <span>{{ item.justifieds_late }}</span>
              </div>
            </b-td>
            <b-td>
              <div
                class="
                  bg-default
                  p-s
                  d-flex
                  justify-content-between
                  align-items-center
                  r-c
                  border-bottom
                "
              >
                <span>ABSENCE:</span>
                <span>{{ item.absences }}</span>
              </div>
              <div
                class="
                  bg-default
                  p-s
                  d-flex
                  justify-content-between
                  align-items-center
                  r-c
                "
              >
                <span>JUSTIFIEDS:</span>
                <span>{{ Number(item.justifieds_abse) }}</span>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    byUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.getReport();
  },
  methods: {
    getReport() {
      let array = this.data;
      if (!this.byUser) {
        array.forEach((element) => {
          let json = element.json_data;
          let hours = 0;
          let overtime = 0;
          let lates = 0;
          let justifiedsLate = 0;
          let absences = 0;
          let justifiedsAbse = 0;
          json.forEach((schedule, index) => {
            hours += schedule.total_hours;
            overtime += schedule.overtime;
            lates += schedule.lates;
            justifiedsLate += schedule.justifieds_late;
            absences += schedule.absences;
            justifiedsAbse += schedule.justifieds_abse;
          });
          this.items.push({
            user_name: !this.byUser ? element.user_name : element.name,
            hours: Number(hours),
            overtime: Number(overtime),
            lates: Number(lates),
            justifieds_late: Number(justifiedsLate),
            absences: Number(absences),
            justifieds_abse: Number(justifiedsAbse),
          });
        });
      } else {
        array.forEach((element) => {
          let json = element.json_data;
          json.forEach((schedule, index) => {
            if (index == json.length - 1) {
              this.items.push({
                user_name: !this.byUser ? element.user_name : element.name,
                hours: schedule.total_hours_filter,
                overtime: schedule.total_overtime,
                lates: schedule.lateness,
                justifieds_late: schedule.jus_late,
                absences: schedule.fouls,
                justifieds_abse: schedule.jus_abs,
              });
            }
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.r-c {
  padding: 5px 5px;
}

table {
  thead {
    text-align: center;
  }

  tbody {
    td {
      div {
        background-color: transparent !important;
        font-weight: 600;
        font-size: 11px;
        cursor: pointer;

        &:nth-child(2) {
          border-bottom: none;
        }
      }

      &:nth-child(1) {
        .container {
          width: 100%;
          height: 60px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: none;

          p {
            font-weight: 400;
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>